import React, { useRef, useContext, useMemo } from 'react';
import cx from 'classnames';
import moment from 'moment';

import { BoardContext } from '../../contexts/BoardContext';
import { useClickOutside } from '../../hooks';

import CreditcardIcon from '@atlaskit/icon/glyph/creditcard';
import OverviewIcon from '@atlaskit/icon/glyph/overview';
import TaskIcon from '@atlaskit/icon/glyph/task';
import Avatar from '@atlaskit/avatar';
import AvatarGroup from '@atlaskit/avatar-group';
import MediaServicesRectangleIcon from '@atlaskit/icon/glyph/media-services/rectangle';
import CheckIcon from '@atlaskit/icon/glyph/check';
import AttachmentIcon from '@atlaskit/icon/glyph/attachment';
import FileIcon from '@atlaskit/icon/glyph/file';
import CommentIcon from '@atlaskit/icon/glyph/comment';
import RoadmapIcon from '@atlaskit/icon/glyph/roadmap';
import MediaServicesTextIcon from '@atlaskit/icon/glyph/media-services/text';
import MediaServicesFullScreenIcon from '@atlaskit/icon/glyph/media-services/full-screen';
import RoomMenuIcon from '@atlaskit/icon/glyph/room-menu';
import CalendarIcon from '@atlaskit/icon/glyph/calendar';
import CheckCircleOutlineIcon from '@atlaskit/icon/glyph/check-circle-outline';
import Tooltip from '@atlaskit/tooltip';
import Textarea from '@atlaskit/textarea';
import Textfield from '@atlaskit/textfield';
import Button, { LoadingButton } from '@atlaskit/button';

import ReactMarkdown, { PluggableList } from 'react-markdown';
import gfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';

import styles from './CardBack.module.scss';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BoardParams } from '../../types/Routes';
import { useState } from 'react';
import { submitComment } from '../../api';

interface DumbCardBackProps {
  id: string;
  boardData: any;
  loading: boolean;
  list: any;
  card: any;
  closeCardBack: () => void;
  showBoardName?: boolean;
  allowCommenting?: boolean;
  onComment?: (action: any) => void;
}

const Modal: React.FC<{
  onClose: () => void;
}> = ({ children, onClose }) => {
  const cardBackRef = useRef(null);

  useClickOutside(cardBackRef, () => {
    onClose();
  });

  return (
    <div className={styles.overlay}>
      <div ref={cardBackRef} className={styles.cardBack}>
        {children}
      </div>
    </div>
  );
};

export const DumbCardBack: React.FC<DumbCardBackProps> = ({
  id,
  boardData,
  loading,
  list,
  card,
  closeCardBack,
  showBoardName,
  allowCommenting,
  onComment,
}) => {
  const [showAddComment, setShowAddComment] = useState(false);
  const [commenterName, setCommenterName] = useState(
    localStorage.getItem('commenterName') || ''
  );
  const [comment, setComment] = useState('');

  const [isSubmittingComment, setIsSubmittingComment] = useState(false);

  const handleOnComment = async () => {
    if (commenterName.length === 0 || comment.length === 0) {
      return;
    }

    // save commentName to local storage
    localStorage.setItem('commenterName', commenterName);

    setIsSubmittingComment(true);

    const action = await submitComment({
      id,
      idCard: card.id,
      idBoard: boardData.id,
      comment,
      commenterName,
    });

    if (onComment) {
      onComment(action);
    }

    setIsSubmittingComment(false);
    setComment('');
    setShowAddComment(false);
  };

  const customFieldsMap = useMemo(() => {
    const map: { [id: string]: any } = {};

    for (const customField of boardData.customFields) {
      map[customField.id] = customField;
    }

    return map;
  }, [boardData.customFields]);

  if (loading) {
    return null;
  }

  return (
    <Modal onClose={closeCardBack}>
      <div className={styles.header}>
        <CreditcardIcon label="Card" />
        <h2 className={styles.cardName}>{card.name}</h2>
      </div>
      <div className={styles.listName}>
        in list {list.name} {showBoardName ? `in board ${boardData.name}` : ''}
      </div>

      {/* Make this into it's own component! */}
      <div className={styles.badgesSection}>
        {!!card.idMembers.length && (
          <div className={styles.badgeContainer}>
            <h3 className={styles.badgeLabel}>Members</h3>
            <div className={styles.badges}>
              <AvatarGroup
                appearance="stack"
                data={boardData.members
                  .filter((member: any) => card.idMembers?.includes(member.id))
                  .map((member: any) => ({
                    name: member.fullName,
                    src: `${member.avatarUrl}/50.png`,
                  }))}
              />
            </div>
          </div>
        )}

        {!!card.labels.length && (
          <div className={styles.badgeContainer}>
            <h3 className={styles.badgeLabel}>Labels</h3>
            <div className={styles.badges}>
              {card.labels.map((label: any) => (
                <div className={cx(styles.label, styles[label.color])}>
                  {label.name}
                </div>
              ))}
            </div>
          </div>
        )}

        {card.badges.start && (
          <div className={styles.badgeContainer}>
            <h3 className={styles.badgeLabel}>Start Date</h3>
            <div className={styles.badges}>
              <div className={styles.dates}>
                {moment(card.badges.start).format('MMM D YYYY [at] h:mm a')}
              </div>
            </div>
          </div>
        )}

        {card.badges.due && (
          <div className={styles.badgeContainer}>
            <h3 className={styles.badgeLabel}>Due Date</h3>
            <div className={styles.badges}>
              <div className={styles.dates}>
                {moment(card.badges.due).format('MMM D YYYY [at] h:mm a')}
              </div>
            </div>
          </div>
        )}

        {boardData.hasBoardKeyPowerUp && (
          <div className={styles.badgeContainer}>
            <h3 className={styles.badgeLabel}>
              {boardData.boardKey ? 'Key' : 'Number'}
            </h3>
            <div className={styles.badges}>
              <div className={styles.dates}>
                {boardData.boardKey
                  ? `${boardData.boardKey}-${card.idShort}`
                  : `#${card.idShort}`}
              </div>
            </div>
          </div>
        )}
      </div>

      {card.desc && (
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <OverviewIcon label="description" />
            <h3 className={styles.sectionName}>Description</h3>
          </div>
          <div className={cx(styles.sectionContent, styles.cardDescriptionMd)}>
            <ReactMarkdown
              remarkPlugins={[gfm, remarkBreaks] as PluggableList}
              skipHtml
            >
              {card.desc}
            </ReactMarkdown>
          </div>
        </div>
      )}

      {card.customFieldItems?.length > 0 && (
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <RoadmapIcon label="custom fields" />
            <h3 className={styles.sectionName}>Custom Fields</h3>
          </div>
          <div className={styles.badgesSection}>
            {card.customFieldItems.map((customFieldItem: any) => {
              try {
                const customField =
                  customFieldsMap[customFieldItem.idCustomField];

                let innerNode: string | React.ReactNode | undefined;
                let customFieldIcon: React.ReactNode | undefined;

                const extraStyles: any[] = [];

                if (customFieldItem.idValue) {
                  const selectedValue = customField.options.find(
                    (opt: any) => opt.id === customFieldItem.idValue
                  );

                  if (selectedValue.value?.text) {
                    innerNode = selectedValue.value.text;

                    if (selectedValue.color !== 'none') {
                      extraStyles.push(
                        styles[`card-label-${selectedValue.color}`]
                      );

                      extraStyles.push(styles.customFieldSelectedWithColor);

                      customFieldIcon = (
                        <RoomMenuIcon label="checkbox" size="small" />
                      );
                    }
                  }
                } else if (customFieldItem.value?.text) {
                  innerNode = customFieldItem.value.text;
                  customFieldIcon = (
                    <MediaServicesTextIcon label="checkbox" size="small" />
                  );
                } else if (customFieldItem.value?.number) {
                  innerNode = customFieldItem.value.number;
                  customFieldIcon = (
                    <MediaServicesFullScreenIcon
                      label="checkbox"
                      size="small"
                    />
                  );
                } else if (customFieldItem.value?.date) {
                  innerNode = moment(customFieldItem.value.date).format(
                    'MMM D YYYY [at] h:mm a'
                  );
                  customFieldIcon = (
                    <CalendarIcon label="checkbox" size="small" />
                  );
                } else if (customFieldItem.value?.checked) {
                  innerNode = <CheckIcon label="checked" size="small" />;
                  customFieldIcon = (
                    <CheckCircleOutlineIcon label="checkbox" size="small" />
                  );
                }

                return (
                  <div
                    className={styles.badgeContainer}
                    key={customFieldItem.id}
                  >
                    <h3 className={cx(styles.badgeLabel, styles.cf)}>
                      {customFieldIcon}
                      <span className={styles.spacer} />
                      {customField.name}
                    </h3>
                    <div className={styles.badges}>
                      <div className={cx(styles.customField, extraStyles)}>
                        {innerNode}
                      </div>
                    </div>
                  </div>
                );
              } catch (e) {
                return null;
              }
            })}
          </div>
        </div>
      )}

      {!!card.attachments.length && (
        <div className={styles.section}>
          <div className={styles.sectionHeader}>
            <AttachmentIcon label="attachments" />
            <h3 className={styles.sectionName}>Attachments</h3>
          </div>
          <div className={styles.sectionContent}>
            {card.attachments.map((attachment: any) => (
              <a
                href={attachment.url}
                target="_blank"
                rel="noreferrer"
                className={styles.attachmentRow}
              >
                <div className={styles.attachmentTile}>
                  {attachment.previews?.length ? (
                    <img src={attachment.previews[0].url} alt="" />
                  ) : (
                    <FileIcon label="attachment" />
                  )}
                </div>
                <div className={styles.attachmentDetails}>
                  <div className={styles.name}>
                    {attachment.name || attachment.fileName || attachment.url}
                  </div>
                  <div className={styles.date}>
                    {`Added ${moment(attachment.date).fromNow()}`}
                  </div>
                </div>
              </a>
            ))}
          </div>
        </div>
      )}

      {card.checklists
        .sort((a: any, b: any) => a.pos - b.pos)
        .map((checklist: any) => (
          <div className={styles.section}>
            <div className={styles.sectionHeader}>
              <TaskIcon label="checklists" />
              <h3 className={styles.sectionName}>{checklist.name}</h3>
            </div>
            <div className={styles.checkList}>
              {checklist.checkItems
                .sort((a: any, b: any) => a.pos - b.pos)
                .map((checkItem: any) => {
                  const checkItemAssignee = boardData.members.find(
                    (m: any) => m.id === checkItem.idMember
                  );

                  return (
                    <div className={styles.checkListItem}>
                      <div className={styles.itemAndName}>
                        {checkItem.state === 'complete' ? (
                          <CheckIcon label="complete" primaryColor="#5e6c84" />
                        ) : (
                          <MediaServicesRectangleIcon
                            label="incomplete"
                            primaryColor="#5e6c84"
                          />
                        )}
                        <div className={styles.itemText}>
                          <ReactMarkdown
                            remarkPlugins={[gfm, remarkBreaks] as PluggableList}
                            skipHtml
                            linkTarget="_blank"
                          >
                            {checkItem.name}
                          </ReactMarkdown>
                        </div>
                      </div>

                      <div className={styles.advancedCheckListFields}>
                        {checkItem.due && (
                          <div className={styles.badgeContainer}>
                            <div className={styles.badges}>
                              <div className={styles.dates}>
                                {moment(checkItem.due).format(
                                  'MMM D YYYY [at] h:mm a'
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        {checkItemAssignee && (
                          <Tooltip content={checkItemAssignee.fullName}>
                            <Avatar
                              name={checkItemAssignee.fullName}
                              src={`${checkItemAssignee.avatarUrl}/50.png`}
                              size="small"
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ))}

      <div className={styles.section}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div className={styles.sectionHeader}>
            <CommentIcon label="comments" />
            <h3 className={styles.sectionName}>Comments</h3>
          </div>

          {allowCommenting && !showAddComment && (
            <Button onClick={() => setShowAddComment(true)}>Add comment</Button>
          )}
        </div>

        {showAddComment && (
          <div className={styles.addCommentSection}>
            <div className={styles.addCommentUser}>
              <Avatar />
              <Textfield
                autoFocus={commenterName.length === 0}
                placeholder="What is your name?"
                onChange={(e) => setCommenterName(e.currentTarget.value)}
                value={commenterName}
              />
            </div>
            <Textarea
              placeholder="Write a comment..."
              onChange={(e) => setComment(e.currentTarget.value)}
              value={comment}
            />

            <div style={{ display: 'flex', gap: '8px' }}>
              <LoadingButton
                appearance="primary"
                onClick={handleOnComment}
                isDisabled={commenterName.length === 0 || comment.length === 0}
                isLoading={isSubmittingComment}
              >
                Save
              </LoadingButton>
              <Button
                onClick={() => {
                  setComment('');
                  setShowAddComment(false);
                }}
                appearance="subtle"
                isDisabled={isSubmittingComment}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}

        {!!card.actions?.length &&
          card.actions.map((action: any) => (
            <div className={styles.commentRow}>
              <Avatar
                name={action.memberCreator.fullName}
                src={`${action.memberCreator.avatarUrl}/50.png`}
              />
              <div className={styles.commentDetails}>
                <div className={styles.commentHeader}>
                  <span className={styles.commentName}>
                    {action.memberCreator.fullName}
                  </span>
                  <span className={styles.commentDate}>
                    {moment(action.date).fromNow()}
                  </span>
                </div>
                <div className={styles.actionComment}>
                  <ReactMarkdown
                    remarkPlugins={[gfm, remarkBreaks] as PluggableList}
                    skipHtml
                  >
                    {action.data.text}
                  </ReactMarkdown>
                </div>
              </div>
            </div>
          ))}
      </div>
    </Modal>
  );
};

export const CardBack: React.FC = () => {
  const { id } = useParams<BoardParams>();
  const {
    boardData,
    loading,
    getListById,
    selectedCard,
    closeCardBack,
    loadActionsForCard,
    pushAction,
  } = useContext(BoardContext);
  const [card, setCard] = useState(selectedCard);
  const list = getListById(card.idList);

  useEffect(() => {
    if (boardData.failedToGetNestedActions) {
      const load = async () => {
        const cardWithActions = await loadActionsForCard(id, card.id);

        setCard(cardWithActions);
      };

      load();
    }
  }, [boardData.failedToGetNestedActions, card.id, id, loadActionsForCard]);

  return (
    <DumbCardBack
      id={id}
      boardData={boardData}
      loading={loading}
      list={list}
      card={card}
      closeCardBack={closeCardBack}
      allowCommenting={boardData.allowCommenting}
      onComment={(action) => {
        if (boardData.failedToGetNestedActions) {
          setCard((prev: any) => {
            return {
              ...prev,
              actions: [action, ...prev.actions],
            };
          });

          return;
        }

        pushAction(card.id, action);
      }}
    />
  );
};
