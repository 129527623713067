import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styles from './App.module.scss';
import { BoardContextProvider } from './contexts';
import { TrelloBoard } from './components/TrelloBoard';
import { TrelloCard } from './components/TrelloCard';

function NoMatch() {
  return (
    <div className={styles.errorState}>
      <div>
        <h1>🤨 Invalid URL.</h1>

        <p>You probably shouldn't be here, but hi.</p>

        <div className={styles.footer}>
          <a href="https://trello.com/power-ups">External Share</a> by{' '}
          <a href="https://powerups.club">Power-Ups Club</a>
        </div>
      </div>
    </div>
  );
}

function App() {
  return (
    <div className={styles.app}>
      <BoardContextProvider>
        <Router>
          <Switch>
            <Route path="/c/:id" component={TrelloCard} />
            <Route path="/:id/:slug" component={TrelloBoard} />
            <Route path="*">
              <NoMatch />
            </Route>
          </Switch>
        </Router>
      </BoardContextProvider>
    </div>
  );
}

export default App;
