const SERVER_URL = `${
  process.env.REACT_APP_SERVER_URL || 'http://localhost:3001'
}/external-share`;

export const loadBoardById = async (id: string, pwd: string) => {
  const response = await fetch(`${SERVER_URL}/sharedBoard/${id}?pwd=${pwd}`, {
    method: 'get',
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error(`Failed with status code: ${response.status}`);
  }
};

export const buildCardsMap = async (cards: any[]) => {
  const cardsMap = new Map<string, any>();

  for (const card of cards) {
    cardsMap.set(card.id, card);
    cardsMap.set(card.shortLink, card);
  }

  return cardsMap;
};

export const loadCardById = async (id: string) => {
  const response = await fetch(`${SERVER_URL}/sharedCard/${id}`, {
    method: 'get',
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error(`Failed with status code: ${response.status}`);
  }
};

export const loadCardByIdFallback = async (
  id: string,
  pwd: string,
  idCard: string
) => {
  const response = await fetch(
    `${SERVER_URL}/sharedBoard/${id}/card/${idCard}?pwd=${pwd}`,
    {
      method: 'get',
    }
  );

  if (response.ok) {
    return response.json();
  } else {
    throw new Error(`Failed with status code: ${response.status}`);
  }
};

export interface SubmitCommentRequest {
  id: string;
  idBoard: string;
  idCard: string;
  comment: string;
  commenterName: string;
}

function getPwd() {
  // get from url query param
  const urlParams = new URLSearchParams(window.location.search);
  let pwd = urlParams.get('pwd');
  if (pwd) {
    return pwd;
  }

  // get from local storage
  pwd = localStorage.getItem('pwd');

  return pwd;
}

export async function submitComment({
  id,
  idBoard,
  idCard,
  comment,
  commenterName,
}: SubmitCommentRequest) {
  const response = await fetch(`${SERVER_URL}/submitComment`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      id,
      idBoard,
      idCard,
      pwd: getPwd(),
      comment,
      commenterName,
    }),
  });

  if (response.ok) {
    return response.json();
  } else {
    throw new Error(`Failed with status code: ${response.status}`);
  }
}
