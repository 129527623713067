/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { useLocation, useParams } from 'react-router';
import { BoardContext } from '../../contexts/BoardContext';
import Spinner from '@atlaskit/spinner';

import { BoardParams } from '../../types/Routes';
import { CardBack } from '../CardBack';

import styles from './TrelloBoard.module.scss';

interface BoardElement extends HTMLElement {
  board: any;
}

interface CardElement extends Element {
  card?: any;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const TrelloBoard: React.FC = () => {
  const { id } = useParams<BoardParams>();
  const { boardData, loadBoard, selectedCard, loading, error } =
    useContext(BoardContext);

  const [loaded, setLoaded] = useState(false);

  const query = useQuery();

  useEffect(() => {
    const pwd = query.get('pwd');

    loadBoard(id, pwd);
  }, []);

  useEffect(() => {
    const renderBoard = async () => {
      const boardContent = document.getElementById('trello-board-content');

      if (!boardContent) {
        return;
      }

      if (boardData == null) {
        return;
      }
      boardContent.innerHTML = '';
      const boardEl = document.createElement('trello-board') as BoardElement;
      boardEl.board = boardData;
      boardContent.appendChild(boardEl);

      const trelloBoardShadowDom =
        document.querySelector('trello-board')?.shadowRoot;

      trelloBoardShadowDom
        ?.querySelectorAll('trello-card')
        .forEach((trelloCard: CardElement) => {
          const a = trelloCard.shadowRoot?.querySelector('a.card');
          const { card } = trelloCard;

          if (a) {
            a.removeAttribute('target');
            a.setAttribute('href', '#');

            a.addEventListener('click', (e) => {
              e.preventDefault();
              const selectCardEvent = new CustomEvent('select-card', {
                detail: card.id,
              });
              window.dispatchEvent(selectCardEvent);
            });
          }

          if (boardData.hasBoardKeyPowerUp) {
            const cardBadges =
              trelloCard.shadowRoot?.querySelector('trello-card-badges');
            if (cardBadges) {
              const badgesContainer =
                cardBadges.shadowRoot?.querySelector('.badges');
              if (badgesContainer) {
                const badgeElement = document.createElement('div');
                badgeElement.classList.add('badge');
                const badgeText = document.createElement('span');
                badgeText.classList.add('badge-text');
                badgeText.innerText = boardData.boardKey
                  ? `${boardData.boardKey}-${card.idShort}`
                  : `#${card.idShort}`;
                badgeElement.appendChild(badgeText);
                badgesContainer.appendChild(badgeElement);
              }
            }
          }
        });

      const trelloBoardHeader =
        trelloBoardShadowDom?.querySelector('.board-header');
      trelloBoardHeader?.remove();

      setLoaded(true);
    };

    if (!loaded) {
      renderBoard();
    }
  }, [boardData]);

  if (error) {
    return (
      <div className={styles.errorState}>
        <div>
          <h1>🙁 Unable to load this board.</h1>

          <p>
            Contact the person who shared this link with you and ask them for a
            new one.
          </p>

          <div className={styles.footer}>
            <a href="https://trello.com/power-ups">External Share</a> by{' '}
            <a href="https://powerups.club">Power-Ups Club</a>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div id="trello-board-content" className={styles.boardContainer} />
      {loading && (
        <div className={styles.loadingContainer}>
          <Spinner size="large" />
        </div>
      )}
      {selectedCard && <CardBack />}
    </>
  );
};
