import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { loadCardById } from '../../api';
import { CardParams } from '../../types/Routes';
import Spinner from '@atlaskit/spinner';

import styles from './TrelloCard.module.scss';
import { DumbCardBack } from '../CardBack/CardBack';

export const TrelloCard: React.FC = () => {
  const { id } = useParams<CardParams>();

  const [cardData, setCardData] = useState<any>();
  const [boardData, setBoardData] = useState<any>();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function load() {
      setLoading(true);

      const data = await loadCardById(id);

      setCardData(data.card);
      setBoardData(data.board);

      document.title = data.card.name;

      setLoading(false);
    }

    load();
  }, [id]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Spinner size="large" />
      </div>
    );
  }

  const boardStyles: any = {
    backgroundImage: `url('${boardData.prefs.backgroundImage}')`,
    backgroundColor: boardData.prefs.backgroundColor,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };

  return (
    <div style={boardStyles}>
      <DumbCardBack
        id={id}
        boardData={boardData}
        card={cardData}
        loading={false}
        closeCardBack={() => {}}
        list={cardData.list}
        showBoardName
        allowCommenting
        onComment={(comment) => {
          cardData.actions = [comment, ...cardData.actions];
        }}
      />
    </div>
  );
};
