import React, { createContext, useCallback, useState } from 'react';
import { useEffect } from 'react';
import { buildCardsMap, loadBoardById, loadCardByIdFallback } from '../api';
import { useHotkeys } from 'react-hotkeys-hook';

interface BoardContextProps {
  loading: boolean;
  boardData: any;
  selectedCard: any;
  error: Error | null;
  closeCardBack: () => void;
  getCardByIdOrShortLink: (id: string) => any;
  getListById: (id: string) => any;
  loadBoard: (id: string, pwd: string | null) => Promise<any>;
  loadActionsForCard: (id: string, idCard: string) => Promise<any>;
  pushAction: (cardId: string, action: any) => void;
}

export const BoardContext = createContext<BoardContextProps>({
  loading: true,
  boardData: null,
  selectedCard: null,
  error: null,
  closeCardBack: () => {
    throw new Error('not implement');
  },
  getCardByIdOrShortLink: (id: string) => {
    throw new Error('not implement');
  },
  getListById: (id: string) => {
    throw new Error('not implement');
  },
  loadBoard: (id: string, pwd: string | null) => {
    throw new Error('not implement');
  },
  loadActionsForCard: (id: string, idCard: string) => {
    throw new Error('not implement');
  },
  pushAction: (cardId: string, action: any) => {
    throw new Error('not implement');
  },
});

export const BoardContextProvider: React.FC = ({ children }) => {
  const [boardData, setBoardData] = useState<any>(null);
  const [cardsMap, setCardsMap] = useState<Map<string, any>>(new Map());
  const [loading, setLoading] = useState(true);
  const [selectedCard, setSelectedCard] = useState<any>(null);
  const [error, setError] = useState<Error | null>(null);

  useHotkeys('esc', () => {
    setSelectedCard(null);
  });

  const pushAction = useCallback(
    (cardId: string, action: any) => {
      const card = cardsMap.get(cardId);

      if (!card) {
        return;
      }

      card.actions = [action, ...card.actions];

      setCardsMap(new Map(cardsMap));
    },
    [cardsMap]
  );

  const loadBoard = useCallback(async (id: string, _pwd: string | null) => {
    setError(null);
    setLoading(true);

    let pwd: string | null = _pwd;

    try {
      if (!pwd) {
        pwd = localStorage.getItem(id);
      }
    } catch (e) {
      console.error('localStorage is not available', e);
    }

    if (!pwd) {
      setLoading(false);
      setError(new Error('missing pwd'));
      return;
    }

    // These two Promises can be consildated on the server side.
    try {
      const _boardData = await loadBoardById(id, pwd);
      setBoardData(_boardData);
      const _cardsMap = await buildCardsMap(_boardData.cards);
      setCardsMap(_cardsMap);
      window.document.title = _boardData.name;

      localStorage.setItem(id, pwd);
    } catch (e) {
      try {
        localStorage.removeItem(id);
      } catch (e) {}
      setError(e as any);
    }

    setLoading(false);
  }, []);

  const getCardByIdOrShortLink = useCallback(
    (idOrShortLink: string) => {
      return cardsMap.get(idOrShortLink);
    },
    [cardsMap]
  );

  const getListById = useCallback(
    (id: string) => {
      const list = boardData.lists.find((list: any) => list.id === id);

      return list;
    },
    [boardData]
  );

  const onCardSelect = useCallback(
    (e: any) => {
      const idCard: string = e.detail;
      const card = getCardByIdOrShortLink(idCard);
      setSelectedCard(card);
    },
    [getCardByIdOrShortLink]
  );

  const closeCardBack = useCallback(() => setSelectedCard(null), []);

  const loadActionsForCard = useCallback(async (id: string, idCard: string) => {
    const pwd = localStorage.getItem(id);
    const card = await loadCardByIdFallback(id, pwd || '', idCard);
    return card;
  }, []);

  useEffect(() => {
    window.addEventListener('select-card', onCardSelect);

    return () => window.removeEventListener('select-card', onCardSelect);
  }, [onCardSelect]);

  return (
    <BoardContext.Provider
      value={{
        loading,
        error,
        boardData,
        getListById,
        getCardByIdOrShortLink,
        loadBoard,
        selectedCard,
        closeCardBack,
        loadActionsForCard,
        pushAction,
      }}
    >
      {children}
    </BoardContext.Provider>
  );
};
