import { useEffect } from 'react';

export function useClickOutside(ref: any, handleClickOutside: () => void) {
  useEffect(() => {
    function _handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClickOutside();
      }
    }

    document.addEventListener('mousedown', _handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', _handleClickOutside);
    };
  }, [ref, handleClickOutside]);
}
